<script setup lang="ts">

</script>

<template>
  <h3 class="font-bold text-4xl leading-tight">
    <slot></slot>
  </h3>
</template>

<style scoped>

</style>
